<template>
    <div class="main">
        <router-view />
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            isRouterAlive: true,
        };
    },

    mounted() {},

    methods: {},
};
</script>

<style></style>
