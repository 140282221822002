import Vue from 'vue';
import Vuex from 'vuex';
import {app, user, activeItem} from './modules';
import getters from './getters';
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
     
        user,
        activeItem,
    },
    state: {},
    mutations: {},
    actions: {},
    getters,
});
