/**
 * 声明：必需要引入sm-crypto与jsbn依赖
 * sm-crypto sm2,sm3,sm4 加解密及验签均在里边
 * jsbn 为了根据私钥生成公钥而引入的
 */
const {BigInteger} = require('jsbn');
const _ = require("sm-crypto/src/sm2/utils");
const SM2 = require('sm-crypto').sm2;
const SM3 = require('sm-crypto').sm3;
const SM4 = require('sm-crypto').sm4;

const {G} = _.generateEcparam();

// 系统后端给你的公钥
const SM2_PUBLIC_KEY = "04362F107AEA3A457A8F755B57B91BE6C34C7E7CF55CA88EE06290C80D341B66D96473B5FDD202FE0C76BE9BE1A290F75E83CC0362B9F9995003C3C26EB104DC02";

// 前端UI私钥，对应的公钥已经给了后端
const SM2_PRIVATE_KEY = "D836074CFF8328C8D50BA2082315007F4A4C3D02E889FFF89455497ACA307004";

// sm2的模式，这里不将其作为变量了，不然前后端写着反而麻烦，C1C2C3-0, C1C3C2-1, 默认使用模式 1(该模式目前比较流行)
const SM2_CIPHER_MODE = 1;

// sm2 签名和验签默认的配置(为了与后端一致，这里必须使用该配置)
const SM2_OPTIONS = {hash: true, der: false};

// sm4的ecb模式的key，长度必须是16个字符，请与后端保持一致
const SM4_ECB_KEY = "2W7nEcscv184(%Ok";

// sm4的cbc模式的key，长度必须是16个字符，请与后端保持一致
const SM4_CBC_KEY = "e@hW%ys)5#16u(P3";

// sm4的cbc模式的iv，长度必须是16个字符，请与后端保持一致
const SM4_CBC_IV = "Q)^6*KiVz7Y0d@vs";

/**
 * 生成sm2的密钥对
 * @param private_key 私钥  如果用户传入了私钥，根据传入的私钥生成对应公钥，如果没有，就随机生成公私钥对
 * @returns {{privateKey: string, publicKey: string}}
 */
const createKey2 = (privateKey) => {
    if (privateKey) {
        const PA = G.multiply(new BigInteger(privateKey, 16));
        const x = _.leftPad(PA.getX().toBigInteger().toString(16), 64);
        const y = _.leftPad(PA.getY().toBigInteger().toString(16), 64);
        const publicKey = '04' + x + y;
        return {'privateKey': privateKey.toLocaleLowerCase(), 'publicKey': publicKey};
    }
    return SM2.generateKeyPairHex(null, null, null);
}

/**
 * sm2 加密
 *
 * @param data 原数据
 * @param publicKey 加密的公钥 如果不传输则使用默认的公钥
 * @returns {string} 加密结果 16进制字符串
 */
const encrypt2 = (data, publicKey) => {
    // 如果publicKey是空的，则使用默认的
    publicKey = publicKey ? publicKey : SM2_PUBLIC_KEY;
    let originalData;
    if (data && (typeof data === 'string') && data.constructor === String) {
        originalData = data;
    } else {
        originalData = JSON.stringify(data);
    }
    // 为了跟后端对接，必须在加密结果之前+04，否则后端没法识别
    return '04' + SM2.doEncrypt(originalData, publicKey, SM2_CIPHER_MODE).toLocaleLowerCase();
}


/**
 * sm2解密
 *
 * @param data 需要解密的密文
 * @param privateKey 解密的私钥 如果不传输则使用默认的私钥
 * @returns {string} 解密结果
 */
const decrypt2 = (data, privateKey) => {
    // 如果privateKey是空的，则使用默认的
    privateKey = privateKey ? privateKey : SM2_PRIVATE_KEY;
    if (data && (typeof data === 'string') && data.constructor === String) {
        // 由于加密+‘04’，所以这里必须去掉最前边的两位才开始解密
        data = data.substring(2).toLocaleLowerCase();
        return SM2.doDecrypt(data, privateKey, SM2_CIPHER_MODE);
    }
}

/**
 * 生成签名
 * {hash: true, der: false} 为了与后端匹配，这里必须使用此配置
 * @param data 原数据
 * @param privateKey 私钥 如果不传输则使用默认的私钥
 * @returns {*} 生成的签名 16进制字符串
 */
const sign2 = (data, privateKey) => {
    // 如果privateKey是空的，则使用默认的
    privateKey = privateKey ? privateKey : SM2_PRIVATE_KEY;
    return SM2.doSignature(data, privateKey, SM2_OPTIONS).toLocaleLowerCase();
}

/**
 * 验签
 * {hash: true, der: false} 为了与后端匹配，这里必须使用此配置
 * @param data 原数据
 * @param hexSign 签名
 * @param publicKey 公钥 如果不传输则使用默认的公钥
 * @returns {boolean|*} true-验签成功 false-验签失败
 */
const verify2 = (data, hexSign, publicKey) => {
    // 如果publicKey是空的，则使用默认的
    publicKey = publicKey ? publicKey : SM2_PUBLIC_KEY;
    return SM2.doVerifySignature(data, hexSign, publicKey, SM2_OPTIONS);
}

/**
 * sm3 该算法不强制要求加盐，请根据自己的实际需求选择是否加盐
 * @param data 原数据
 * @param salt 盐，当其为null或者空时不加盐值计算
 * @returns {*} 摘要计算结果 16进制字符串
 */
const sm3 = (data, salt) => {
    let options = null;
    if (salt) {
        options = {key: _.utf8ToHex(salt)}
    }
    return SM3(data, options).toLocaleLowerCase();
}

/**
 * sm4的ecb模式加密
 * 优点：
 * 简单；
 * 有利于并行计算；
 * 误差不会被传递；
 * 缺点：
 * 不能隐藏明文的模式；
 * 可能对明文进行主动攻击；
 * @param data 需要加密的数据
 * @param key 如果不传输则使用默认的key
 * @returns {*|string|[]} 加密结果 16进制字符串
 */
const encrypt4ECB = (data, key) => {
    key = key ? key : SM4_ECB_KEY;
    return SM4.encrypt(data, _.utf8ToHex(key)).toLocaleLowerCase();
}

/**
 * sm4的ecb模式解密
 * @param data 需要解密的字符串 16进制字符串
 * @param key 如果不传输则使用默认的key
 * @returns {*|string|[]} 解密结果
 */
const decrypt4ECB = (data, key) => {
    key = key ? key : SM4_ECB_KEY;
    return SM4.decrypt(data, _.utf8ToHex(key));
}


/**
 * sm4的cbc模式，带有iv值
 * 优点：
 * 不容易主动攻击，安全性好于ECB，是SSL、IPSec的标准；
 * 缺点：
 * 不利于并行计算；
 * 误差传递；
 * 需要初始化向量IV；
 * @param data 需要加密的字符串
 * @param key 如果不传输则使用默认的key
 * @param iv 偏移量
 * @returns {*|string|[]} 加密结果 16进制字符串
 */
const encrypt4CBC = (data, key, iv) => {
    key = key ? key : SM4_CBC_KEY;
    iv = iv ? iv : SM4_CBC_IV;
    let originalData;
    if (data && (typeof data === 'string') && data.constructor === String) {
        originalData = data;
    } else {
        originalData = JSON.stringify(data);
    }
    return SM4.encrypt(originalData, _.utf8ToHex(key), {
        mode: 'cbc',
        iv: _.utf8ToHex(iv)
    }).toLocaleLowerCase();
}

/**
 * sm4 cbc模式解密
 * @param data 需要解密的字符串 16进制字符串
 * @param key 如果不传输则使用默认的key
 * @param iv 偏移量
 * @returns {*|string|[]} 解密结果
 */
const decrypt4CBC = (data, key, iv) => {
    key = key ? key : SM4_CBC_KEY;
    iv = iv ? iv : SM4_CBC_IV;
    return SM4.decrypt(data, _.utf8ToHex(key), {
        mode: 'cbc',
        iv: _.utf8ToHex(iv)
    });
}

export {
    createKey2,
    encrypt2,
    decrypt2,
    sign2,
    verify2,
    sm3,
    encrypt4ECB,
    decrypt4ECB,
    encrypt4CBC,
    decrypt4CBC
}