import Vue from 'vue';
import Storage from 'vue-ls';
import config from '@/config';

Vue.use(Storage, config.storageOptions);

const USERINFO = 'USERINFO';
const SHAREID = 'SHAREID';
const openID = 'OPEN_ID';
const firstUrl = 'FIRST_URL'; //第一次进入的URL
/**
 * 移除登录信息
 */
const removeOpenId = () => {
    Vue.ls.remove(openID);
};

/**
 * 设置登录信息
 */
const getOpenId = () => {
    if (!Vue.ls) return null;
    return Vue.ls.get(openID);
};

/**
 * 获取登录信息
 */
const setOpenId = (val) => {
    return Vue.ls.set(openID, val);
};

/**
 * 移除参加活动手机号
 */
const removeUserInfo = () => {
    Vue.ls.remove(USERINFO);
};

/**
 * 设置参加活动手机号
 */
const getUserInfo = () => {
    if (!Vue.ls) return null;
    return Vue.ls.get(USERINFO);
};

/**
 * 获取参加活动手机号
 */
const setUserInfo = (val) => {
    return Vue.ls.set(USERINFO, val);
};

/**
 * 移除分享人ID
 */
const removeShareId = () => {
    Vue.ls.remove(SHAREID);
};

/**
 * 设置分享人ID
 */
const getShareId = () => {
    if (!Vue.ls) return null;
    return Vue.ls.get(SHAREID);
};

/**
 * 获取分享人ID
 */
const setShareId = (val) => {
    if (!Vue.ls) return null;
    if(!val) return
    Vue.ls.set(SHAREID, val);
    return
};

/**
 * 移除分享人ID
 */
 const removeFirstUrl = () => {
    Vue.ls.remove(firstUrl);
};

/**
 * 设置分享人ID
 */
const getFirstUrl = () => {
    if (!Vue.ls) return null;
    return Vue.ls.get(firstUrl);
};

/**
 * 获取分享人ID
 */
const setFirstUrl = (val) => {
    if (!Vue.ls) return null;
    if(!val) return
    Vue.ls.set(firstUrl, val);
    return
};

export {
    getFirstUrl,
    setFirstUrl,
    removeFirstUrl,
    removeUserInfo,
    getUserInfo,
    setUserInfo,
    setShareId,
    getShareId,
    removeShareId,
    setOpenId,
    getOpenId,
    removeOpenId
};