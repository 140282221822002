import * as util from '@/utils/util';
import {
    Toast,
    Dialog
} from 'vant';
import {
    ImagePreview
} from 'vant';
import config from '@/config';
/**
 * 显示关注公众号
 */
export const showConcern = () => {
    Dialog.alert({
            title: '温馨提示',
            message: '您还未关注公众号，需关注公众号后才能参与活动哦~~', // 提示的内容
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: '#0086FF',
        })
        .then(() => {
            window.location.replace(
                `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwMzE5NzQ3OQ==#wechat_redirect`,
            );
            // window.location.href = `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwMzE5NzQ3OQ==#wechat_redirect`

        })
        .catch(() => {});
};


export const weixinOfficialLogin = () => {
    let redirectUrl = config.redirectUrl;
    redirectUrl = encodeURIComponent(redirectUrl);
    window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`,
    );
};

export const generateSessionId = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

/**
 * JSON对象数组转换器 [带参数]
 * @param {array} imageList 图片地址数组
 * @return {array}
 */
export const imageListConverter = (imageListJson) => {
    if (!imageListJson) return [];
    if (typeof imageListJson == 'string') {
        imageListJson = imageListJson.replace(/'/g, '"');
        const imageList = JSON.parse(imageListJson);
        return imageList;
    }
    return [];
};
/**
 * 预览图片 [带参数]
 * @param {array} imageList 图片地址数组
 * @param {number} index 页面参数
 */
export const previewSwiperImage = (imageList, index = 0) => {
    imageList = imageList.map((item) => item.url);
    ImagePreview({
        images: imageList,
        startPosition: index, // 开始位置，默认为 0
    });
};

/**
 * 显示成功提示框
 */
export const showSuccess = (msg, callback, duration = 1500) => {
    Toast.success({
        message: msg, // 提示的内容
        onOpened: callback,
        duration: duration,
    });
};

/**
 * 显示失败提示框
 */
export const showError = (msg, callback, showCancel = false) => {
    Dialog.alert({
            title: '温馨提示',
            message: msg, // 提示的内容
            showConfirmButton: true,
            showCancelButton: showCancel,
            confirmButtonColor: '#0086FF',
        })
        .then(() => {
            callback && callback();
        })
        .catch(() => {});
};

/**
 * 显示纯文字提示框
 */
export const showToast = (msg, duration = 1500, forbidClick = true) => {
    Toast({
        message: msg, // 提示的内容
        icon: 'none',
        forbidClick: forbidClick, // 是否显示透明蒙层，防止触摸穿透
        duration, // 提示的延迟时间，单位毫秒，默认：1500
    });
};

/**
 * 生成完整的H5地址 [带参数]
 * @param h5Url
 * @param {string} path 页面路径
 * @param {object} params 页面参数
 * @return {string}
 */
export const buildUrL = (h5Url, path, params) => {
    let complete = h5Url;
    if (!util.isEmpty(path)) {
        complete += '#/' + path;
        const shareParamsStr = getShareUrlParams(params);
        if (!util.isEmpty(shareParamsStr)) {
            complete += '?' + shareParamsStr;
        }
    }
    return complete;
};