/**
 * uuid和timestamp都是非私密性消息，没必要加密,只要确保防篡改即可
 * 直接使用sm3计算摘要防篡改
 */

import {sm3} from "@/utils/smUtils";

// nonce计算摘要的盐，请与后端保持一致
const NONCE_SALT = "Js4@d^8Y)X0k*s&4";

/**
 * 获取随机的uuid
 * @returns {*}
 */
const getUUID = () => {
	function S4() {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	}

	return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
}

/**
 * 获取当前时间戳
 * @returns {*}
 */
const getTimestamp = () => {
	return Date.now();
}

/**
 * 得到最终的nonce
 * @returns {*|string|[]}
 */
const getNonce = () => {
	const uuid = getUUID();
	const timestamp = getTimestamp();
	const sign = sm3(uuid + "|" + timestamp, NONCE_SALT);
	return uuid + "|" + timestamp + "|" + sign;
}

export {
	getNonce
}