import {
    getUserInfo,
    setUserInfo,
    removeUserInfo,
    setOpenId,
    getOpenId
} from '@/utils/storage-utils';

import * as LoginApi from '@/api/login';
import * as indexApi from '@/api/index';
import {
    setIsConcern
} from '@/utils/session-utils';
import {
    showConcern
} from '@/core/app';
// 登陆成功后执行
const loginSuccess = (commit, dispatch, {
    val
}) => {};

const user = {
    state: {
        userInfo: getUserInfo()
    },

    mutations: {
        SETUSERINFO: (state, value) => {
            setUserInfo(value)
            state.userInfo = value;
        },
    },

    actions: {
        // 微信公众号一键授权登录 (获取用户基本信息)
        LoginWxOfficial({
            commit,
            dispatch
        }, data) {
            return new Promise((resolve, reject) => {
                LoginApi.loginWxOfficial(data, {
                        isPrompt: false
                    })
                    .then((response) => {
                        const {
                            isConcern,
                            openid
                        } = response.data;
                        setOpenId(openid)

                        resolve(response);
                    })
                    .catch(reject);
            });
        },

        // Openid换取用户信息
        getUserInfo({
            commit,
            dispatch
        }, data) {
            const openid = getOpenId();
            return new Promise((resolve, reject) => {
                LoginApi.getUserInfo({
                        openid
                    }, {
                        isPrompt: false
                    })
                    .then((response) => {
                        commit('SETUSERINFO', response.data)
                        resolve(response);
                    })
                    .catch(reject);
            });
        },

        getjoinActivityInfo({
            commit,
            dispatch
        }) {
            const openid = getOpenId();
            return new Promise((resolve, reject) => {
                LoginApi.getLottery({
                        openid
                    }, {
                        isPrompt: false
                    })
                    .then((response) => {
                        const data = response.data
                        if (data) {
                            const userInfo = this.state.user.userInfo
                            const newUserInfo = Object.assign(userInfo, data)
                            commit('SETUSERINFO', newUserInfo)
                            resolve(newUserInfo)
                        }


                    })
                    .catch(reject);
            });
        },

        loginOut({
            commit,
            dispatch
        }) {
            return new Promise((resolve, reject) => {
                commit('SETUSERINFO', null);
                removeUserInfo()
            });
        },

    },
};

export default user;