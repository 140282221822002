import Vue from 'vue';
import VueRouter from 'vue-router';
import GlobalLayout from '@/components/layouts/GlobalLayout';
import platform from '@/core/platform';
import Nprogress from 'nprogress';
import {

    getFirstUrl,
    setFirstUrl
} from '@/utils/storage-utils';
import {
    getSessionId
} from '@/utils/session-utils';
Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
},
{
    path: '/standings',
    name: 'standings',
    component: () => import('@/views/home/standings.vue'),
},
{
    path: '/poster',
    name: 'poster',
    component: () => import('@/views/home/poster.vue'),
},
{
    path: '/prizeDrawResult',
    name: 'prizeDrawResult',
    component: () => import('@/views/home/prizeDrawResult.vue'),
},
{
    path: '/notSupported',
    name: 'notSupported',
    component: () => import('@/views/notSupported.vue'),
}

];

const router = new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes,
});


//路由守卫
router.beforeEach((to, from, next) => {
    Nprogress.start();
    const {
        path,
        query
    } = to;

    if (platform != 'WXOFFICIAL' && path !== '/notSupported') {
        next({
            name: 'notSupported',
            query: {

            },
        });
    }

    if (!getFirstUrl()) {
        setFirstUrl(window.location.href);
    }

    if (path !== '/' && !getSessionId() && path !== '/notSupported') {
        next({
            name: 'home',
            query: query,
        });
    }

    next();


});
//在路由跳转后用NProgress.done()标记下结束
router.afterEach(() => {
    Nprogress.done()
})
export default router;