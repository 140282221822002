<template>
    <div id="app">
        <keep-alive :include="cacheList">
            <router-view />
        </keep-alive>
    </div>
</template>
<script>
import * as util from '@/utils/util';
import platform from '@/core/platform';
import {getFirstUrl} from '@/utils/session-utils';
import {getWexinint} from '@/utils/wxSdk';
import config from '@/config';
export default {
    metaInfo: {
        titleTemplate: '全言新品抽送',
        htmlAttrs: {
            lang: 'zh-CN',
            amp: true,
        },
        meta: [
            {
                name: 'viewport',
                content: 'width=device-width,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no',
            },
        ],
    },

    data() {
        return {
            cacheList: ['home'],
        };
    },

    mounted() {
        this.setRemUnit();
        // this.getIOSconfit();
    },
    methods: {

        getIOSconfit() {
            if (platform === 'WXOFFICIAL') {
                const devicePlatform = util.getDevicePlatform();
                if (devicePlatform === 'iOS') {
                    const path = getFirstUrl();
                    getWexinint(path);
                }
            }
        },
        
        setRemUnit() {
            let resizeTimer = null;
            window.addEventListener('resize', function () {
                if (resizeTimer) {
                    clearTimeout(resizeTimer);
                }
                resizeTimer = setTimeout(function () {
                    mainrem();
                }, 400);
            });
            function mainrem() {
                const html = document.querySelector('html');
                let width = html.getBoundingClientRect().width;
                // width = width > 750 ? 750 : width;
                html.style.fontSize = width / 10 + 'px';
            }
            mainrem();
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, oldTo) {
                // const devicePlatform = util.getDevicePlatform();
                // if (platform === 'WXOFFICIAL') {
                //     if (devicePlatform === 'iOS') {
                //         getWexinint(window.location.href.split('#')[0]);
                //     } else {
                //         getWexinint(window.location.href);
                //     }
                // }
            },
        },
    },
};
</script>
<style lang="scss">
#app {
    min-height: 100vh;
    overflow: hidden;
}
</style>
