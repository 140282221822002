import * as setting from "@/api/setting";
import config from '@/config';
import store from '@/store';
import * as util from '@/utils/util';
import platform from '@/core/platform';
import {
  Toast
} from 'vant';
// 当前重新配置微信sdk数
let reconnections = 0;
const inintConfig = (errMsg) => {
  reconnections += 1;
  if (reconnections <= 2) {
    getWexinint()
  } else {
    reconnections = 0
    Toast.fail(errMsg);
  }
}
export const getWexinint = () => {
  return new Promise((resolve, reject) => {
    let url = ''
    const devicePlatform = util.getDevicePlatform();
    if (platform !== 'WXOFFICIAL') {
      return
    }
    if (devicePlatform === 'iOS') {
      try {
        url = window.location.href.split('#')[0];
      } catch (e) {
        url = window.location.href
      }
    } else {
      url = window.location.href
    }
    if (typeof url != 'string') {
      url = config.redirectUrl
    }
    setting.jssdkConfig({
      url: url
    }).then((result) => {
      const data = result.data
      const userInfo = store.getters.userInfo;
      data.jsApiList = ["updateAppMessageShareData", "updateTimelineShareData", "hideMenuItems"]
      wx.config(data);
      wx.ready(function () {
        wx.updateAppMessageShareData({
          title: '全言新品抽送',
          link: `${config.redirectUrl}#/?userId=${userInfo?.id || 0}`,
          desc: '【第一弹】白泽动组态气象监测站正式发布!正在抽送，快来试试手气吧!',
          imgUrl: 'http://117.133.148.94:35068/uploadFiles/1101.png',
          success() {
            // 设置成功
            resolve()
          },
          fail() {
            inintConfig('微信分享好友配置失败,请重新进入页面')
            reject()
          },
        });

        wx.updateTimelineShareData({
          title: '全言新品抽送',
          link: `${config.redirectUrl}#/?userId=${userInfo?.id || 0}`,
          desc: '【第一弹】白泽动组态气象监测站正式发布!正在抽送，快来试试手气吧!',
          imgUrl: 'http://117.133.148.94:35068/uploadFiles/1101.png',
          success: function () {
            // 设置成功
          },
          fail() {},
        });
        //批量隐藏功能按钮接口
        wx.hideMenuItems({
          menuList: ['menuItem:copyUrl'] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
        });
      });
      wx.error(() => {
        inintConfig('微信sdk配置失败,请重新进入页面')
        reject()
      })
    }).catch(err => {
      inintConfig('获取微信sdk配置参数失败,请重新进入页面')
      reject()
    });
  })
}

export const banWexShare = (url) => {


}