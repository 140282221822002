const activeItem = {
    namespaced: true,
    state: {
        // 认证修改对象
        certificationItem: null,
    },

    mutations: {
        SET_CERTIFICATIONITEM: (state, value) => {
            state.certificationItem = value;
        },
    },

    actions: {},
};

export default activeItem;
