import axios from 'axios';
import {
    Toast,
    Dialog
} from 'vant';
import config from '@/config';
import {
    getNonce
} from '@/utils/nonceUtils';
import {
    getToken,
    getEncryptSm,
    postEncryptSm,
    decryptSm
} from '@/utils/EnAndDecryptUtils';

import store from '@/store';
class HttpRequest {
    constructor() {}

    getInsideConfig(options) {

        const configData = {
            publicPath: config.apiUrl,
            headers: {
                Authorization: getToken(),
                NonceStr: getNonce(),
            },
        };
        if (options.url.indexOf('paycodeCompare/verifyPayCode') != -1 || options.url.indexOf('file/upload') != -1) {
            configData.headers['Content-Type'] = 'multipart/form-data;';
        } else {
            configData.headers['Content-Type'] = 'application/json;charset=UTF-8';
        }
        return configData;
    }
    interceptors(instance, apiSettingOptions) {
        // 请求拦截
        instance.interceptors.request.use(
            (config) => {
                let message = '请稍等...';

                if (
                    config.url.indexOf('paycodeCompare/verifyPayCode') != -1 ||
                    config.url.indexOf('file/upload') != -1
                ) {
                    // post接口
                    if (config.data) {
                        if (apiSettingOptions.load) {
                            Toast.loading({
                                message: message,
                                duration: 0,
                            });
                        }
                        config.data = config.data;
                    }
                    // get接口
                    if (config.params) {
                        config.params = config.data;
                    }
                } else {
                    // post接口
                    if (config.data) {
                        if (apiSettingOptions.load) {
                            Toast.loading({
                                message: message,
                                duration: 0,
                            });
                        }
                        config.data = postEncryptSm(config.data);
                    }
                    // get接口
                    if (config.params) {
                        config.params = getEncryptSm(config.params);
                    }
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            },
        );

        // 响应拦截
        instance.interceptors.response.use(
            (response) => {
                Toast.clear();
                // 如果返回类型为blob，代表其是流文件，整个返回，不做任何处理
                if (response.data instanceof Blob) {
                    return response;
                }
                if (response.data.code === 200) {
                    response.data.data = decryptSm(response.data.data);

                    // 如果遇到后台返回401或者403报错，直接退出返回到登录页
                } else if (response.data.code === 401 || response.data.code === 403) {
                    Toast.fail(response.data.msg);

                    // 其他报错直接给出报错提示，但是不做任何操作
                    return Promise.reject(response.data);
                } else if (response.data.code === 2003) {
                    store.dispatch('loginOut')
                    Toast.fail('当前用户参与活动信息已失效，请重新参与活动');
                    setTimeout(() => {
                        const redirectUrl = config.redirectUrl;
                        window.location.replace(`${redirectUrl}`);
                    }, 1500)

                } else {
                    Toast.fail(response.data.msg);
                    return Promise.reject(response);
                }
                return response.data;
            },
            (error) => {
                Toast.fail(error);

                return Promise.reject(error);
            },
        );
    }
    request(options) {
        const instance = axios.create();
        let apiSettingOptions = {
            load: true, //（默认 true 说明：本接口是否提示加载动画）
        };
        if (options.apiSettingOptions) {
            apiSettingOptions = Object.assign(apiSettingOptions, options.apiSettingOptions);
        }
        options.baseURL = config.apiUrl;
        if (options.headers) {
            options.headers = Object.assign(this.getInsideConfig(options).headers, options.headers);
        }
        // method 转大写
        options.method = options.method.toUpperCase();
        if (options.data instanceof FormData) {} else if (options.method == 'POST' && options.data) {}
        options = Object.assign(this.getInsideConfig(options), options);
        this.interceptors(instance, apiSettingOptions);
        delete options.apiSettingOptions;

        return instance(options);
    }
}
export default HttpRequest;