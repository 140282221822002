import store from '@/store/index';
import platform from '@/core/platform';

export default {
    data() {
        return {
            platform,
        };
    },
    computed: {
       
    },
};
