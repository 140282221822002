import {encrypt4CBC, decrypt4CBC} from '@/utils/smUtils'
import configs from '@/config'
// import {getLoginInfo} from "@/utils/storage-utils";

/**
 * 是否开启全局加密解密
 * @type {boolean}
 */
const IS_GLOBAL = false;

/**
 * 加密解密的key和iv，与后台保持一直，请勿随意更改
 */
const GLOBAL_KEY = 'g7U(71#P1HY7%&X&';
const GLOBAL_IV = 'v1&M57KN2OAfo43X';

/**
 * 将加密解密key和iv
 */
let globalEncryptKey = '';
let globalEncryptIv = '';


const getToken = () => {
	return '';
	// return '7b22747970223a226a7774222c22616c67223a22736d222c22657870223a313732343431333032303933357d.9ce4c9406966e9a6582aa8b89a462acba669a7a36f348b4f4e4654e8ba5115a31fd42ddcf80a0e58e0aec430b4f4ca3b0aa4421c02848b4ebdc5ef229eb1dfebd74cc2f7a7fc49b265728c6c9323d186be0910108c4cc2f789b34f7d0f8d1b53a06e68651b98603d10768b91f0b2553e9b0118f3786a5b0bfe5274c318d5675e.dfd73f0a05cb10bddee2cd304101d60afe4ce954d125e973a2c7fea90dbba60f4e22d7b78778b9fc4e42a12f86fc40645b168e1f5529ce2f719a62fc8174e29e';
}

const getEncryptSm = (params) => {
// 如果使用全局加密，那就进行加密
	if (IS_GLOBAL) {
		// 获取加解密key和iv
		getKeyAndIv();
		// params 加密 get 加密
		// 对数组要单独处理，需要[1,2,3,4]处理成"1,2,3,4"的样子，其他的直接加密
		// 对象里有对象后台会报错，入参复杂不要用get接口
		let tempData = Object.assign({}, params);
		Object.keys(tempData).forEach(key => {
			// get请求支持数组
			if (tempData[key]?.constructor === Array) {
				// 对数组要单独处理，需要[1,2,3,4]处理成"1,2,3,4"的样子，其他的直接加密
				tempData[key] = tempData[key].toString();
			}
		})
		params = {data: encrypt4CBC(JSON.stringify(tempData), globalEncryptKey, globalEncryptIv)};
	}
	return params;
}

const postEncryptSm = (data) => {
	// 如果使用全局加密，那就进行加密
	if (IS_GLOBAL) {
		// 获取加解密key和iv
		getKeyAndIv();
		// data 加密 post 接口加密，不管什么数据，都一律加密，多复杂的参数后台都可以解析出来
		const tempData = Object.assign({}, data);
		data = {data: encrypt4CBC(JSON.stringify(tempData), globalEncryptKey, globalEncryptIv)};
	}
	return data;
}

const decryptSm = (data) => {
	if (IS_GLOBAL) {
		// 获取加解密key和iv
		getKeyAndIv();
		// data解密
		if (typeof (data) === 'string') {
			let decString = decrypt4CBC(data, globalEncryptKey, globalEncryptIv);
			if (decString.charAt(0) === "{" || decString.charAt(0) === "[") {
				//JSON.parse
				decString = JSON.parse(decString);
			}
			data = decString;
		}
	}
	return data;
}

const getKeyAndIv = () => {
	const token = getToken();
	// 初始化用处是的key和iv
	globalEncryptKey = GLOBAL_KEY;
	globalEncryptIv = GLOBAL_IV;
	// 如果存在token，则使用token中的加密，否则使用默认的加密
	if (token) {
		const tmp = getLoginInfo();
		if (tmp) {
			if (!tmp[configs.iv] || !tmp[configs.key]) {
				console.log("提示信息：前端获取key或iv失败，请检查登录信息，不行就重新登录吧....");
			}
			globalEncryptKey = tmp[configs.key];
			globalEncryptIv = tmp[configs.iv];
		}
	}
}

export {
	getToken,
	getEncryptSm,
	postEncryptSm,
	decryptSm
}