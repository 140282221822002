const sessionId = 'SESSION_ID'; //校验首页参数
const firstUrl = 'FIRSTURL_'; //第一次进入的URL
const prefix = 'PRIZEDRAW_';
/**
 * 获取校验信息
 */
const getSessionId = () => {
    return sessionStorage.getItem(`${prefix}${sessionId}`);
};
/**
 * 设置校验信息
 */
const setSessionId = (val) => {
    return sessionStorage.setItem(`${prefix}${sessionId}`, val);
};
/**
 * 清空校验信息
 */
const removeSessionId = () => {
    return sessionStorage.removeItem(`${prefix}${sessionId}`);
};

/**
 * 获取第一次进入的URL
 */
const getFirstUrl = () => {
    return sessionStorage.getItem(`${prefix}${firstUrl}`);
};
/**
 * 设置第一次进入的URL
 */
const setFirstUrl = (val) => {
    return sessionStorage.setItem(`${prefix}${firstUrl}`, val);
};
/**
 * 清空第一次进入的URL
 */
const removeFirstUrl = () => {
    return sessionStorage.removeItem(`${prefix}${firstUrl}`);
};


/**
 * 获取是否关注公众号
 */
const getIsConcern = () => {
    return sessionStorage.getItem(`${prefix}${firstUrl}`);
};
/**
 * 设置是否关注公众号
 */
const setIsConcern = (val) => {
    return sessionStorage.setItem(`${prefix}${firstUrl}`, val);
};
/**
 * 清空是否关注公众号
 */
const removeIsConcern = () => {
    return sessionStorage.removeItem(`${prefix}${firstUrl}`);
};


export {
    getSessionId,
    setSessionId,
    removeSessionId,
    getFirstUrl,
    setFirstUrl,
    removeFirstUrl,
    getIsConcern,
    setIsConcern,
    removeIsConcern

};