import axios from '@/utils/api.request';



// api地址
const api = {
    saveLotteryInfo: 'lottery/saveLotteryInfo'
};

// 保存抽奖信息
export function saveLotteryInfo(params) {
    return axios.request({
        url: api.saveLotteryInfo,
        method: 'post',
        data: params,
    });
}