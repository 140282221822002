import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import bootstrap from './core/bootstrap';
import mixin from './core/mixins/app';
import Meta from 'vue-meta';
import Vant from 'vant';
import './main.scss';
import 'nprogress/nprogress.css'
import config from '@/config';


// vue-ls 缓存

import {showToast, showSuccess, showError, previewSwiperImage} from './core/app';

Vue.prototype.$toast = showToast;
Vue.prototype.$success = showSuccess;
Vue.prototype.$error = showError;
Vue.prototype.$previewSwiperImage = previewSwiperImage;
Vue.prototype.$bus = new Vue();

// element
import dayjs from 'dayjs';
require('dayjs/locale/zh-cn')
dayjs.locale('zh-cn') // 'en'
import relativeTime from 'dayjs/plugin/relativeTime'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(relativeTime)
dayjs.extend(isBetween)
Vue.use(Meta);
Vue.use(Vant);
Vue.config.productionTip = false;
// 全局mixin
Vue.mixin(mixin);

new Vue({
    router,
    store,
    created: bootstrap,
    render: (h) => h(App),
}).$mount('#app');
