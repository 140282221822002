import axios from '@/utils/api.request';

// api地址
const api = {
    loginWxOfficial: 'lottery/getUserStatus',
    getLottery: 'lottery/getLottery',
    getUserInfo:'lottery/getUserStatusByOpenId'
};

// 微信公众号一键授权登录 (获取微信用户基本信息)
export function loginWxOfficial(params) {
    return axios.request({
        url: api.loginWxOfficial,
        method: 'get',
        params: params,
    });
}

// 获取参加活动信息
export function getLottery(params) {
    return axios.request({
        url: api.getLottery,
        method: 'get',
        params: params,
    });
}

// 手机号换userId
export function getUserInfo(params) {
    return axios.request({
        url: api.getUserInfo,
        method: 'get',
        params: params,
    });
}