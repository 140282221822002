import axios from '@/utils/api.request';

// api地址
const api = {
  sendCode: 'lottery/sendCode',
  getQCode: 'lottery/getQCode',
  jssdkConfig: 'lottery/getConfig'
}

// 发送验证码
export function sendCode(params) {

  return axios.request({
    url: api.sendCode,
    method: 'get',
    params: params,
  });
}


// 获取二维码
export function getQCode(params) {

  return axios.request({
    url: api.getQCode,
    method: 'get',
    params: params,
  });
}

// 公众号jsdk
export function jssdkConfig(params) {
  return axios.request({
    url: api.jssdkConfig,
    method: 'get',
    params: params,
  });
}