export default {
    apiUrl: process.env.NODE_ENV === 'production' ?
        'http://activity.liqilong.top:34000/' : 'http://activity.liqilong.top:34000/',

    // token本地存储的名称
    token: 'token',

    // 对称加密的秘钥
    key: 'key',

    // 对称加密的iv
    iv: 'iv',

    /**
     * @description 公众号appid
     */
    appid: 'wx26df4b16ca505149',
    
    /**
     * @description 微信公众号授权登录回调路径
     */
    // redirectUrl: 'http://36.147.70.232:7898/',
    redirectUrl: 'http://activity.liqilong.top/',

    // 上传附件回显nginx映射地址
    imageUrl: process.env.NODE_ENV === 'production' ? 'https://84ghjg.natappfree.cc/files/' : '',

    // 本地存储配置
    storageOptions: {
        // key prefix 前缀
        namespace: 'PRIZEDRAW_',
        // name variable Vue.[ls] or this.[$ls], 引用名称
        name: 'ls',
        // storage name session, local, memory 存储类型
        storage: 'local',
    },

    // 本地存储配置
    sessionStorageOptions: {
        // key prefix 前缀
        namespace: 'PRIZEDRAW_',
        // name variable Vue.[ls] or this.[$ls], 引用名称
        name: 'ss',
        // storage name session, local, memory 存储类型
        storage: 'session',
    },

    /**
     * 微信需要config页面
     */
    wxConfigUrlList: [],

    activityInfo:{
        startTime:'2024-12-10 08:00:00', //开始时间
        endTime:'2024-12-16 18:00:00' //结束时间
    }
};

